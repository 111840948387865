<template>
  <div class="spirecta-toggle-wrapper">
    <b-dropdown right
      :text="$t('common.toggle_columns')"
      variant='outline-secondary'
      class="float-right text-regular toggleColumnWrapper"
      size="sm"
    >
      <b-dropdown-item
        @click="toggleColumns('description')"
        :class="{ active: options.description }"
      >
        {{$t('common.description')}}
      </b-dropdown-item>

      <b-dropdown-item
        @click="toggleColumns('verified')"
        :class="{ active: options.verified }"
      >
        {{$t('common.verified')}}
      </b-dropdown-item>

      <b-dropdown-item
        @click="toggleColumns('actions')"
        :class="{ active: options.actions }"
      >
        {{$t('common.delete')}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'BalanceFixToggleColumns',
  props: {
    options: {
      type: Object,
      default: function () {
        return {
          description: false,
          varified: false,
          actions: false
        }
      }
    }
  },
  methods: {
    toggleColumns (columnToDisplay) {
      this.$emit('column-toggle', columnToDisplay)
    }
  }
}
</script>
