<template>
  <div class="balance-fix-sidebar balance-fix-sidebar-correction">
    <b-row no-gutters class="mt-2">
      <h3>{{$t(translationPath + 'step1_title')}}</h3>
      <b-col :class="{ 'show-err': correctionDateFieldClasses.indexOf('is-invalid') !== -1 }">
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="amount-date"
          required
          :invalid-feedback="invalidAmountDateFeedback"
          :label="$t(translationPath + 'date')"
        >
          <datepicker
            id="amount-date"
            v-model="amountDate"
            :input-class="amountDateFieldClasses"
            :typeable="true"
            :required="true"
            :format="'yyyy-MM-dd'"
            :placeholder="'YYYY-MM-DD'"
            :language="selectedLang"
            aria-describedby="input-2-live-feedback"
            :monday-first="startWeekByMonday"
            @input="onAmountDateInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          required
          :label="$t(translationPath + 'spirecta_amount', { date: amountDateForLabel })"
        >
          <currency-input
            ref="CurrencyInputDateAmount"
            v-model="dateAmount"
            :disabled="true"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="actual-amount"
          required
          :invalid-feedback="invalidActualAmountFeedback"
          :label="$t(translationPath + 'real_amount', { date: amountDateForLabel })"
        >
          <currency-input
            ref="CurrencyInputActualAmount"
            id="actual-amount"
            v-model="actualAmount"
            class="mb-2"
            :class="acctualAmountFieldClasses"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <h3 class="pt-4">{{$t(translationPath + 'step2_title')}}</h3>
    <b-row no-gutters>
      <b-col :class="{ 'show-err': correctionDateFieldClasses.indexOf('is-invalid') !== -1 }">
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="correction-date"
          required
          :invalid-feedback="invalidCorrectionDateFeedback"
          :label="$t(translationPath + 'new_transaction_date')"
        >
          <datepicker
            id="correction-date"
            v-model="correctionDate"
            :input-class="correctionDateFieldClasses"
            :typeable="true"
            :required="true"
            :format="'yyyy-MM-dd'"
            :placeholder="'YYYY-MM-DD'"
            :language="selectedLang"
            aria-describedby="input-2-live-feedback"
            :monday-first="startWeekByMonday"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="title"
          required
          :invalid-feedback="invalidTitleFeedback"
          :label="$t(translationPath + 'new_transaction_description')"
        >
          <b-form-input
            v-model="title"
            id="title"
            class="mb-2"
            :state="$v.title.$dirty ? !$v.title.$error : null"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="col-12">
        {{$t(translationPath + 'new_transaction_amount')}}
      </b-col>
      <b-col class="col-12">
        <currency-input
          ref="CurrencyInputCorrectionAmount"
          v-model="correctionAmount"
          class="mb-2"
          @input="onCorrectionAmountInput"
        />
      </b-col>
    </b-row>
    <b-row no-gutters class="mb-0 pb-0">
      <b-col>
        <b-form-group
          label-class="align-self-center pr-md-0 pr-lg-4 text-black"
          label-for="title"
          label-cols="10"
          :label="$t(translationPath + 'set_as_initial_value')"
          required
        >
          <b-form-checkbox
            v-model="isSetAsInitialValue"
            id="title"
            class="mb-2 mt-2"
            :checked-value="true"
            :unchecked-value="false"
            @input="onSetAsInitialValueInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row no-gutters class="pt-3">
      <b-col>
        <b-button variant="primary" class="mr-2 mb-2" :disabled="!isLoaded || disableButtons" @click.prevent="onClickPreview"> {{ $t(translationPath + 'btn_preview') }}</b-button>
        <b-button v-if="isPreviewLoaded" variant="outline-secondary" class="mr-2 mb-2" :disabled="!isLoaded || disableButtons" @click.prevent="onClickClearPreview"> {{ $t(translationPath + 'btn_clear_preview') }}</b-button>
        <b-button variant="primary" class="mr-2 mb-2" :disabled="!isLoaded || disableButtons" @click.prevent="onClickFixBalance"> {{ $t(translationPath + 'btn_fix_balance') }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import CurrencyInput from '@/components/common/CurrencyInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const isDateFormatCorrect = getter => function (val) {
  return moment(val).isValid()
}

const isCorrectionAmountNotZero = getter => function () {
  return Boolean(this.correctionAmount)
}

export default {
  name: 'BalanceFixSidebarCorrection',
  components: { Datepicker, CurrencyInput },
  props: {
    selectedLang: {
      type: Object
    },
    startWeekByMonday: {
      type: Boolean,
      default: true
    },
    accountOptions: {
      type: Array,
      default: null
    },
    isLoaded: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: 'SEK'
    },
    locale: {
      type: String,
      default: 'en-US'
    },
    isPreviewLoaded: {
      type: Boolean,
      default: false
    },
    disableButtons: {
      type: Boolean,
      default: false
    },
    accountId: {
      type: Number,
      default: 0
    },
    firstTransactionDate: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    }
  },
  data () {
    return {
      translationPath: 'transactions.balance-fix.sidebar.correction.',
      amountDate: new Date(),
      dateAmount: 0,
      actualAmount: 0,
      correctionDate: new Date(),
      title: '',
      correctionAmount: 0,
      isSetAsInitialValue: false
    }
  },
  validations: {
    title: { required, minLength: minLength(2), maxLength: maxLength(191) },
    actualAmount: { required, isCorrectionAmountNotZero: isCorrectionAmountNotZero() },
    amountDate: { required, isDateFormatCorrect: isDateFormatCorrect() },
    correctionDate: { required, isDateFormatCorrect: isDateFormatCorrect() },
    form: ['title', 'actualAmount', 'amountDate', 'correctionDate']
  },
  computed: {
    amountDateForLabel () {
      return moment(this.amountDate).format('YYYY-MM-DD')
    },
    acctualAmountFieldClasses () {
      return {
        'is-valid': this.$v.actualAmount.$dirty && !this.$v.actualAmount.$error,
        'is-invalid': this.$v.actualAmount.$dirty && this.$v.actualAmount.$error
      }
    },
    amountDateFieldClasses () {
      let str = 'form-control input-date mb-2 '
      if (this.$v.amountDate.$dirty && !this.$v.amountDate.$error) {
        str += ' is-valid'
      } else if (this.$v.amountDate.$dirty && this.$v.amountDate.$error) {
        str += 'is-invalid'
      }
      return str
    },
    correctionDateFieldClasses () {
      let str = 'form-control input-date mb-2 '
      if (this.$v.correctionDate.$dirty && !this.$v.correctionDate.$error) {
        str += ' is-valid'
      } else if (this.$v.correctionDate.$dirty && this.$v.correctionDate.$error) {
        str += 'is-invalid'
      }
      return str
    },
    invalidTitleFeedback () {
      if (this.$v.title.required === false) { return this.$t(this.translationPath + 'errors.title.required') }
      if (this.$v.title.minLength === false) { return this.$t(this.translationPath + 'errors.title.min_length') }
      if (this.$v.title.maxLength === false) { return this.$t(this.translationPath + 'errors.title.max_length') }
      return ''
    },
    invalidActualAmountFeedback () {
      if (this.$v.actualAmount.required === false) { return this.$t(this.translationPath + 'errors.actual_amount.required') }
      if (this.$v.actualAmount.isCorrectionAmountNotZero === false) { return this.$t(this.translationPath + 'errors.actual_amount.not_zero_amount') }
      return ''
    },
    invalidAmountDateFeedback () {
      if (this.$v.amountDate.required === false) { return this.$t(this.translationPath + 'errors.amount_date.required') }
      if (this.$v.amountDate.isDateFormatCorrect === false) { return this.$t(this.translationPath + 'errors.amount_date.invalid_format') }
      return ''
    },
    invalidCorrectionDateFeedback () {
      if (this.$v.correctionDate.required === false) { return this.$t(this.translationPath + 'errors.correction_date.required') }
      if (this.$v.correctionDate.isDateFormatCorrect === false) { return this.$t(this.translationPath + 'errors.correction_date.invalid_format') }
      return ''
    }
  },
  created () {
    this.title = this.$t(this.translationPath + 'new_transaction_description_default')
    if (this.accountId) {
      this.fetchDateAmount()
    }
  },
  methods: {
    onAmountDateInput (date) {
      this.correctionDate = date
      this.fetchDateAmount()
    },
    onCorrectionAmountInput (value) {
      this.actualAmount = value + this.dateAmount
      this.$refs.CurrencyInputActualAmount.$rerender()
    },
    onClickClearPreview () {
      this.$emit('clear-preview')
    },
    onClickPreview () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.$emit('preview', this.getFormData())
    },
    onClickFixBalance () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }

      this.$emit('fix-balance', this.getFormData())
    },
    onSetAsInitialValueInput () {
      if (this.isSetAsInitialValue) {
        this.amountDate = moment(this.firstTransactionDate).startOf('month').toDate()
        this.correctionDate = moment(this.firstTransactionDate).startOf('month').toDate()
        this.title = this.$t(this.translationPath + 'initial_value')

        const amt = this.correctionAmount
        this.fetchDateAmount()
          .finally(() => {
            this.correctionAmount = amt
            this.$refs.CurrencyInputCorrectionAmount.$rerender()
          })
      }
    },
    getFormData () {
      return {
        amountData: this.amountDate,
        dateAmount: this.dateAmount,
        actualAmount: this.actualAmount,
        correctionDate: this.correctionDate,
        title: this.title,
        correctionAmount: this.correctionAmount,
        type: 'correction_transaction'
      }
    },
    async fetchDateAmount () {
      if (!this.accountId || !this.amountDate) {
        return null
      }

      return new Promise((resolve, reject) => {
        const endDate = moment(this.amountDate).format('YYYY-MM-DD')
        axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/meta?account_id=${this.accountId}&end_date=${endDate}&round_values=0`)
          .then(response => {
            this.dateAmount = Number(response.data.data.amount_sum)
            this.actualAmount = Number(response.data.data.amount_sum)
            this.$refs.CurrencyInputActualAmount.$rerender()
            this.$refs.CurrencyInputDateAmount.$rerender()
            resolve(response)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    }
  },
  watch: {
    actualAmount () {
      this.correctionAmount = this.actualAmount - this.dateAmount
      this.$refs.CurrencyInputCorrectionAmount.$rerender()
    },
    dateAmount () {
      this.correctionAmount = this.actualAmount - this.dateAmount
      this.$refs.CurrencyInputCorrectionAmount.$rerender()
    },
    accountId () {
      this.fetchDateAmount()
    }
  }
}
</script>
